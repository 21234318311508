.expandable-section-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
}

.expandable-section-header-content {
    width: 100%;
}

.expandable-section-details {
    padding-left: 46px;
}
.expandable-section div div:first-child {
    align-items:center;
}