@import '@amzn/awsui-design-tokens/polaris.scss';

.time-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.radio-selector {
    display: flex;
  }
  
  .radio-selector input[type="radio"] {
    display: none;
    width: 0;
    color: #879596;
  }
  
  .radio-selector label {
      display: inline-block;
      background-color: $color-background-input-default;
      padding: 4px 4px;
      border: 1px solid $color-border-button-normal-default;;
      color: $color-text-button-normal-active;
      min-width: 35px;
      text-align: center;
  }
  
  .radio-selector input[type="radio"]:checked + label {
      background-color: #0073bb;
      color: $color-text-button-primary-active;
      font-weight: bold;
      border: 1px solid $color-border-button-normal-hover;
  }
  
  .radio-selector label:hover {
    filter: brightness(95%);
  }

  .invalid-time {
    color: #d13212;
  }