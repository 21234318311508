@import "@amzn/awsui-design-tokens/polaris.scss";

.awsui {
  footer {
    background-color: $color-background-home-header;
    color: $color-text-home-header-default;
    padding: 0 20px;
    padding-top: 3px;
    text-align: left;
    line-height: 30px;
    min-height: 30px;
    position: relative;

    #copyright {
      color: $color-text-home-header-secondary;
      text-align: left;
    }

    awsui-icon {
      margin-right: 0.7em;
    }

    a {
      color: $color-text-home-header-default;
      min-width: 50px;
      text-align: center;
      text-decoration: none;
      padding: 0 1em 0 0;
      margin-right: 1em;

      &:not(:last-child) {
        border-right: 1px solid $color-text-home-header-secondary;
      }
    }
  }
}
