@import '@amzn/awsui-design-tokens/polaris.scss';

.weekday-selector {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px;
  }

  .weekday-selector div{
    height: 22px;
  }
  
  
  .weekday-selector input[type="checkbox"] {
    display: none;
    width: 0;
  }
  
  .weekday-selector label {
      display: inline-block;
      background-color: $color-background-input-default;
      padding: 4px 15px;
      border: 1px solid $color-border-button-normal-default;
      color: $color-text-button-normal-active;
      min-width: 25px;
      text-align: center;
  }
  
  .weekday-selector input[type="checkbox"]:checked + label {
      background-color: #0073bb;
      font-weight: bold;
      color: $color-text-button-primary-active;
      border: 1px solid $color-border-button-normal-hover;
  }
  
  .weekday-selector input[type="checkbox"]:enabled + label:hover {
    filter: brightness(95%);
  }

  .invalid-selection {
    color: #d13212;
  }