.employee-photo img {
    border-radius: 50%;
    object-fit: cover;
    align-items: center;
}

.employee-photo {
    display: flex;
    justify-content: center;
    align-items: center;
}