@import "@amzn/awsui-design-tokens/polaris.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  // HELPERS
  .pull {
    &-right {
      float: right;
    }
    &-left {
      float: left;
    }
  }

  .align {
    &-right {
      text-align: right;
    }
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
  }

  .color {
    &-primary {
      color: $color-background-button-primary-default;
    }
  }

  .small-font {
    font-size: 0.8em;
  }
}
